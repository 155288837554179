import Checkout from './checkout.route';

export default function CheckoutPage() {
  return (
    <>
      <title>Clair de Lune Salão de Beleza - Checkout</title>
      <Checkout />
    </>
  );
}
