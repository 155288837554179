import Survey from './survey.route';

export default function SurveyPage() {
  return (
    <>
      <title>Clair de Lune Salão de Beleza - Pesquisa de satisfação</title>
      <meta property="og:title" content="Avalie seu atendimento" />
      <meta property="og:description" content="Ajude-nos a melhorar! Dê seu feedback sobre o atendimento." />
      <meta property="og:type" content="company" />
      <meta property="og:image" content="https://salaoclairdelune.com.br/assets/thumb-metatags-pesquisa.jpg" />
      <meta property="og:url" content={`https://salaoclairdelune.com.br/pesquisa`} />
      <meta property="og:site_name" content="Clair de Lune Salão de Beleza" />
      <meta property="og:locale" content="pt_BR" />
      <meta name="twitter:card" content="summary" />
      <meta name="twitter:url" content={`https://salaoclairdelune.com.br/pesquisa`} />
      <meta name="twitter:description" content="Ajude-nos a melhorar! Dê seu feedback sobre o atendimento." />
      <meta name="twitter:title" content="Avalie seu atendimento" />
      <meta name="twitter:image" content="https://salaoclairdelune.com.br/assets/thumb-metatags-pesquisa.jpg" />
      <Survey />
    </>
  );
}
