import { Link, Outlet, useLocation } from 'react-router-dom';

export default function Layout() {
  const location = useLocation();
  const isAllVisible = !(/checkout|pesquisa/gm).test(location.pathname);

  return (
    <div style={{ height: '100%' }}>
      <nav className="menu">
        <Link to="/">
          <img src={`${process.env.REACT_APP_BASE_URL}/assets/logo-horizontal-320.png`} alt="Clair de Lune Salão de Beleza" />
        </Link>
        {isAllVisible && (
          <ul className="menu-list">
              <>
                <li className="menu-item">
                  <Link to="/agendar">
                    Agendar
                  </Link>
                </li>
                <li className="menu-item">
                  <Link to="/#clubes">
                    Clube
                  </Link>
                </li>
              </>
            <li className="menu-item menu-item-icon">
              <a
                href="https://www.facebook.com/people/Clair-de-Lune/61560906628518/"
                target="_blank" rel="noreferrer"
              >
                <img src={`${process.env.REACT_APP_BASE_URL}/assets/icon-facebook.svg`} alt="Facebook" />
              </a>
            </li>
            <li className="menu-item menu-item-icon">
              <a
                href="https://instagram.com/salaoclairdelune"
                target="_blank" rel="noreferrer"
              >
                <img src={`${process.env.REACT_APP_BASE_URL}/assets/icon-instagram.svg`} alt="Instagram" />
              </a>
            </li>
            <li className="menu-item menu-item-icon">
              <a
                href="https://wa.me/5521986841208"
                target="_blank" rel="noreferrer"
              >
                <img src={`${process.env.REACT_APP_BASE_URL}/assets/icon-whatsapp.svg`} alt="WhatsApp" />
              </a>
            </li>
          </ul>
        )}
      </nav>

      <Outlet />
    </div>
  );
}
