
import type { PropsWithChildren } from 'react';

interface BaseButtonProps {
  onClick: () => void;
  className?: string;
  alt?: string;
  icon?: string;
  disabled?: boolean;
}

export default function BaseButton(options: PropsWithChildren<BaseButtonProps>) {
  const {
    onClick,
    className = '',
    alt = '',
    icon,
    disabled = false,
    children,
  } = options;

  return (
    <div className={`base-button-container ${className}`.trim()}>
      <button
        type="button"
        onClick={disabled ? undefined : onClick}
        className="base-button"
      >
        {icon !== undefined && <img src={icon} alt={alt} />}
        <span>{children}</span>
      </button>
    </div>
  );
};
