import Article from './article.route';

export default function ArticlePage() {
  return (
    <>
      <title>Clair de Lune Salão de Beleza - Artigos</title>
      <Article />
    </>
  );
}
