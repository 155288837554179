import { useContext, useState } from "react";
import { MainContext } from "../contexts/main.context";
import { initialModalData } from "../components/Modal";
import { Club } from "../types";
import useClubRedirectToPaymentHook from "./use-club-redirect-to-payment.hook";

type UseSubscriptionModalHookOptions = {
  club: Club;
}

type UseSubscriptionModalHookReturn = {
  showSubscriptionModal: () => void;
}

function ModalContent({ club }: { club: Club }) {
  const [termsChecked, setTermsChecked] = useState(false);
  const [hasMegaHair, setHasMegaHair] = useState<boolean>();
  const isValid = termsChecked && (club.hasException ? hasMegaHair !== undefined : true);

  const { clicked, redirectToPayment } = useClubRedirectToPaymentHook({ isValid });

  return (
    <div className="subscription-modal-container">
      {club.hasException && (
        <>
          <div className="subscription-modal-text">Você possui mega hair com 200g ou mais?</div>
          <div
            className={`subscription-modal-megahair${
              clicked && hasMegaHair === undefined ? ' subscription-modal-megahair-clicked' : ''
            }`}
          >
            <input
              type="radio"
              id="yes"
              value="true"
              name="megahair"
              onChange={(e) => setHasMegaHair(e.target.value === 'true')}
            />
            <label htmlFor="yes" className="subscription-modal-megahair-label">Sim</label>
            <input
              type="radio"
              id="no"
              value="false"
              name="megahair"
              onChange={(e) => setHasMegaHair(e.target.value === 'true')}
            />
            <label htmlFor="no" className="subscription-modal-megahair-label">Não</label>
          </div>
        </>
      )}
      <div
        className={`subscription-modal-checkbox${
          clicked && !termsChecked ? ' subscription-modal-checkbox-clicked' : ''
        }`}
      >
        <input
          type="checkbox"
          id="terms"
          name="terms"
          defaultChecked={termsChecked}
          onClick={() => setTermsChecked(!termsChecked)}
        />
        <label htmlFor="terms" className="subscription-modal-checkbox-label">
          Li e aceito os&nbsp;
          <a
            href={`${process.env.REACT_APP_BASE_URL}/documents/Condicoes-de-Contratacao-dos-Clubes-de-Assinatura-Clair-de-Lune.pdf`}
            rel="noreferrer"
            target="_blank"
          >
            termos e condições
          </a>
        </label>
      </div>
      <button
        className="subscription-modal-button"
        onClick={() => redirectToPayment((hasMegaHair ? club.exceptionId : club.id) || '')}
        rel="noreferrer"
      >
        Assinar
      </button>
    </div>
  )
}

export default function useSubscriptionModalHook(
  options: UseSubscriptionModalHookOptions
): UseSubscriptionModalHookReturn {
  const { club } = options;

  const { setMainContext } = useContext(MainContext);

  function resetModalData() {
    setMainContext?.({ modalData: initialModalData })
  }

  function showSubscriptionModal() {
    setMainContext?.({
      modalData: {
        isVisible: true,
        JSXContent: () => <ModalContent club={club} />,
        onClose: resetModalData,
      },
    });
  }

  return {
    showSubscriptionModal
  }
}
