import CheckoutComplete from './checkout-complete.route';

export default function CheckoutCompletePage() {
  return (
    <>
      <title>Clair de Lune Salão de Beleza - Checkout</title>
      <CheckoutComplete />
    </>
  );
}
