import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Club } from '../../types';
import getHairClubs from '../../services/get-hair-clubs';
import getNailClubs from '../../services/get-nail-clubs';
import getPersonalizedClubs from '../../services/get-personalized-clubs';
import useClubRedirectToPaymentHook from '../../hooks/use-club-redirect-to-payment.hook';

export default function Checkout() {
  const { clubId } = useParams();
  const [currentClub, setCurrentClub] = useState<Club>();
  const [isException, setIsException] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);
  const { redirectToPayment, clicked } = useClubRedirectToPaymentHook({
    isValid: termsChecked
  });

  useEffect(() => {
    const clubList = [...getHairClubs(), ...getNailClubs(), ...getPersonalizedClubs()];

    const club = clubList.find(club => {
      const exception = club.exceptionId === clubId;
      if (exception) setIsException(true);

      return exception || club.id === clubId
    });

    setCurrentClub(club);
  }, [clubId, isException]);

  const backgroundImage = `${process.env.REACT_APP_BASE_URL}/assets/background-checkout-${currentClub?.type === 'hair' ? 'hair' : 'nails'}.png`

  return (
    <div className="checkout-container" style={{ backgroundImage: `url('${backgroundImage}')` }}>
      {currentClub === undefined && <span className="checkout-club-container">Clube não encontrado</span>}
      {currentClub !== undefined && (
        <div className="checkout-club-container">
          <span className="checkout-club-name">
            <span>Clube</span>
            {currentClub.name}
          </span>
          <span className="checkout-club-description">
            {currentClub.description}
          </span>
          <span className="checkout-club-price">
            <span className="checkout-club-price-preffix">R$</span>
            {isException ? currentClub.exceptionPrice : currentClub.price}
            <span className="checkout-club-price-suffix">/mês</span>
          </span>
          <ul className="checkout-club-services">
            {currentClub.services.map((item) => <li key={item}>{item}</li>)}
          </ul>
          <div className={`checkout-club-checkbox${clicked && !termsChecked ? ' checkout-club-checkbox-clicked' : ''}`}>
            <input
              type="checkbox"
              id="terms"
              name="terms"
              defaultChecked={termsChecked}
              onClick={() => setTermsChecked(!termsChecked)}
            />
            <label htmlFor="terms">
              Li e aceito os&nbsp;
              <a
                href={`${process.env.REACT_APP_BASE_URL}/documents/Condicoes-de-Contratacao-dos-Clubes-de-Assinatura-Clair-de-Lune.pdf`}
                rel="noreferrer"
                target="_blank"
              >
                termos e condições
              </a>
            </label>
          </div>
          <button
            className="checkout-club-button"
            onClick={() => redirectToPayment((isException ? currentClub?.exceptionId : clubId) || '')}
            rel="noreferrer"
          >
            Assinar
          </button>
        </div>
      )}
    </div>
  );
}
