import { useState } from 'react';
import { useParams } from 'react-router-dom';

import BaseInput from '../../components/BaseInput';

import style from './survey.module.scss';
import Transition from '../../components/transition';
import BaseButton from '../../components/BaseButton';
import sendSurveyService from '../../services/send-survey.service';

enum STEPS {
  RATE,
  COMMENT,
  END,
  __LENGTH
}

const positiveComments = [
  'Atendimento atencioso / profissional',
  'Ambiente agradável / bem localizado',
  'Boa qualidade dos produtos',
  'Bom resultado do serviço',
  'Facilidade para agendar horário',
  'Boa higiene e organização do salão',
  'Boas promoções e programas de fidelidade',
];

const negativeComments = [
  'Tempo de espera alto',
  'Dificuldade para agendar',
  'Atendimento pouco atencioso',
  'Resultado do serviço não ficou como esperado',
  'Preço do serviço não condiz com a experiência',
  'Ambiente desconfortável',
  'Produtos utilizados não foram satisfatórios',
  'Higiene do local poderia melhorar',
];

export default function Survey() {
  const { base64Tel } = useParams();
  const [progress, setProgress] = useState(STEPS.RATE);
  const [rate, setRate] = useState(-1);
  const [comments, setComments] = useState('');
  const [suggestions, setSuggestions] = useState<string[]>([]);
  const [updatedRange, setUpdatedRange] = useState('Data');
  const currentProgress = progress * 100 / (STEPS.__LENGTH - 1) || 10;
  const isBackButtonDisabled = progress === 0 || progress === STEPS.END;
  const isPositiveReview = rate === 5;
  const commentSuggestions = isPositiveReview ? positiveComments : negativeComments;

  async function sendSurvey(options?: { rate?: number }) {
    const { rate: newRate } = options || {};

    const updatedRangeValue = await sendSurveyService({
      tel: base64Tel,
      rate: newRate || rate,
      comments,
      suggestions: suggestions.join(),
      method: updatedRange !== 'Data' ? 'PUT' : 'POST',
      range: updatedRange,
    });

    setUpdatedRange(updatedRangeValue);
  }

  function onChangeRate(e: React.FormEvent<HTMLInputElement>) {
    setRate(Number(e.currentTarget.value));
    setProgress(STEPS.COMMENT);
    sendSurvey({ rate: Number(e.currentTarget.value) });
  }

  function onChangeSuggestions(suggestion: string) {
    const isSuggestionSelected = suggestions.includes(suggestion);

    if (isSuggestionSelected) {
      setSuggestions(suggestions.filter((s) => s !== suggestion));
      return;
    }

    setSuggestions([...suggestions, suggestion]);
  }

  function onClickSend() {
    sendSurvey();
    setProgress(STEPS.END);
  }

  return (
    <>
      <div className={style.container}>
        <BaseButton
          className={`${style.backButton} ${isBackButtonDisabled ? style.backButtonDisabled : ''}`.trim()}
          onClick={() => setProgress(progress - 1)}
          disabled={isBackButtonDisabled}
          alt="Voltar"
          icon="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAs0lEQVR4nNXWPQoCMRCG4fcEWsgq+AOKlXowj6mlhZ2uhWujNuolVhYiLJIUwpcPHJj6IZNkZuBPYgKcgC3QzYlcgDrkDuiokTFQtZBPrpXIKIGUQE+FDMKd1F95BoZKpIwgVThlVuQKTFVIP4HcgJkSOUaQOzBXIocI8gAWKqRIIE9gqUKa2EeQF7BSIlaocJXO9hisz9v6Ya0tyNpUrWPCOviso9y6nNDCmpJtcq5bP8UbhdWADWvaLVIAAAAASUVORK5CYII="
        >
          Voltar
        </BaseButton>
        <Transition isVisible={progress === STEPS.RATE}>
          <div className={style.wrapper}>
            <div className={style.title}>
              Dê uma nota para nosso atendimento!
            </div>
            <div className={style.starWrap}>
              <input className={style.star} checked type="radio" value="-1" id="skip-star" name="star-radio" onChange={onChangeRate} />
              <label className={`${style.starLabel} ${style.starLabelHidden}`}></label>

              <input className={style.star} type="radio" id="st-1" value="1" name="star-radio" onChange={onChangeRate} />
              <label className={style.starLabel} htmlFor="st-1">
                <div className={`${style.starShape} ${rate >= 1 ? style.starShapeFocused : ''}`.trim()} />
                <span className={style.starNumber}>1</span>
              </label>
              <input className={style.star} type="radio" id="st-2" value="2" name="star-radio" onChange={onChangeRate} />
              <label className={style.starLabel} htmlFor="st-2">
                <div className={`${style.starShape} ${rate >= 2 ? style.starShapeFocused : ''}`.trim()} />
                <span className={style.starNumber}>2</span>
              </label>
              <input className={style.star} type="radio" id="st-3" value="3" name="star-radio" onChange={onChangeRate} />
              <label className={style.starLabel} htmlFor="st-3">
                <div className={`${style.starShape} ${rate >= 3 ? style.starShapeFocused : ''}`.trim()} />
                <span className={style.starNumber}>3</span>
              </label>
              <input className={style.star} type="radio" id="st-4" value="4" name="star-radio" onChange={onChangeRate} />
              <label className={style.starLabel} htmlFor="st-4">
                <div className={`${style.starShape} ${rate >= 4 ? style.starShapeFocused : ''}`.trim()} />
                <span className={style.starNumber}>4</span>
              </label>
              <input className={style.star} type="radio" id="st-5" value="5" name="star-radio" onChange={onChangeRate} />
              <label className={style.starLabel} htmlFor="st-5">
                <div className={`${style.starShape} ${rate >= 5 ? style.starShapeFocused : ''}`.trim()} />
                <span className={style.starNumber}>5</span>
              </label>
            </div>
          </div>
        </Transition>
        <Transition isVisible={progress === STEPS.COMMENT}>
          <div className={style.wrapper}>
            <div className={style.title}>
              {isPositiveReview ? 'Quer deixar algum elogio ou sugestão?' : 'O que podemos melhorar?'}
            </div>
            <BaseInput
              className={style.input}
              type="text"
              placeholder="Escreva aqui..."
              value={comments}
              onChange={(value) => setComments(value)}
              isValid={() => true}
            />
            {commentSuggestions.map((suggestion) => (
              <div
                key={suggestion}
                className={`${style.suggestion} ${suggestions.includes(suggestion) ? style.suggestionSelected : ''}`.trim()}
                onClick={() => onChangeSuggestions(suggestion)}
              >
                {suggestion}
              </div>
            ))}
            <BaseButton
              className={style.button}
              onClick={onClickSend}
              alt={comments.length > 0 || suggestions.length > 0 ? 'Enviar' : 'Pular'}
            >
              {comments.length > 0 || suggestions.length > 0 ? 'Enviar' : 'Pular'}
            </BaseButton>
          </div>
        </Transition>
        <Transition isVisible={progress === STEPS.END}>
          {isPositiveReview ? (
            <div className={style.wrapper}>
              <div className={style.title}>
                Que bom que gostou! 💖
              </div>
              <div className={style.description}>
                <a href="https://www.google.com/maps/place/Clair+de+Lune+Sal%C3%A3o+de+Beleza/@-22.9998253,-43.6348232,17z/data=!3m1!4b1!4m18!1m9!3m8!1s0x9bef4255acbf55:0x1c2954b17d42a0a4!2sClair+de+Lune+Sal%C3%A3o+de+Beleza!8m2!3d-22.9998253!4d-43.6322483!9m1!1b1!16s%2Fg%2F11w4c6grm0!3m7!1s0x9bef4255acbf55:0x1c2954b17d42a0a4!8m2!3d-22.9998253!4d-43.6322483!9m1!1b1!16s%2Fg%2F11w4c6grm0?entry=ttu&g_ep=EgoyMDI1MDMxOS4yIKXMDSoASAFQAw%3D%3D">
                  Clique aqui para nos ajudar deixando um <strong>comentário no Google 📍</strong>
                </a>
              </div>
            </div>
          ) : (
            <div className={style.wrapper}>
              <div className={style.title}>
                Recebemos seu feedback!
              </div>
              <div className={style.description}>
                Obrigada por contribuir com nossa melhoria, esperamos te ver novamente em breve 💖
              </div>
            </div>
          )}
        </Transition>
      </div>
      <div className={style.progressBar} style={{ width: `${currentProgress}%`}}/>
    </>
  );
}
