import Home from './home.route';

export default function HomePage() {
  return (
      <>
        <meta property="og:title" content="Beleza por assinatura em Pedra de Guaratiba" />
        <meta property="og:description" content="Faça suas unhas e cabelos semanalmente por um preço mensal acessível. Assine agora!" />
        <meta property="og:type" content="company" />
        <meta property="og:image" content="https://salaoclairdelune.com.br/assets/thumb-metatags-clair-de-lune-salao-de-beleza.jpg" />
        <meta property="og:url" content="https://salaoclairdelune.com.br/" />
        <meta property="og:site_name" content="Clair de Lune Salão de Beleza" />
        <meta property="og:locale" content="pt_BR" />
        <meta name="twitter:card" content="summary" />
        <meta name="twitter:url" content="https://salaoclairdelune.com.br/" />
        <meta name="twitter:description" content="Faça suas unhas e cabelos semanalmente por um preço mensal acessível. Assine agora!" />
        <meta name="twitter:title" content="Beleza por assinatura em Pedra de Guaratiba" />
        <meta name="twitter:image" content="https://salaoclairdelune.com.br/assets/thumb-metatags-clair-de-lune-salao-de-beleza.jpg" />
        <title>Clair de Lune Salão de Beleza</title>

        <Home />
      </>
  );
}
