import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Club } from '../../types';

import getHairClubs from '../../services/get-hair-clubs';
import getNailClubs from '../../services/get-nail-clubs';
import getPersonalizedClubs from '../../services/get-personalized-clubs';
import usePersistedState from '../../hooks/persisted-state.hook';

import BaseInput from '../../components/BaseInput';
import BaseButton from '../../components/BaseButton';

import style from './checkout-complete.module.scss';

enum STEPS {
  CONTACT,
  PAYMENT,
  END,
  __LENGTH
}

export default function CheckoutComplete() {
  const { clubId, step = STEPS.CONTACT } = useParams();
  const [currentClub, setCurrentClub] = useState<Club>();
  const [isException, setIsException] = useState(false);
  const [progress, setProgress] = useState(Number(step));
  const [phoneNumber, setPhoneNumber] = usePersistedState('', 'checkout-complete_phone-number');
  const [email, setEmail] = usePersistedState('', 'checkout-complete_email');
  const [name, setName] = usePersistedState('', 'checkout-complete_name');
  const currentProgress = progress * 100 / (STEPS.__LENGTH - 1) || 10;

  // validation in case of user uses back button or close and re-open page
  // restart from the current step
  useEffect(() => {
    for (let i = 0; i < progress; i++) {
      if (i === STEPS.PAYMENT) {
        if (!isValidPhone(phoneNumber) || !isValidEmail(email) || !isValidName(name)) {
          setProgress(STEPS.CONTACT);
          break;
        }
      }
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const clubList = [...getHairClubs(), ...getNailClubs(), ...getPersonalizedClubs()];

    const club = clubList.find(club => {
      const exception = club.exceptionId === clubId;
      if (exception) setIsException(true);

      return exception || club.id === clubId
    });

    setCurrentClub(club);
  }, [clubId, isException]);

  const isValidEmail = (email: string): boolean => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidPhone = (phone: string): boolean => {
    return phone.length > 13;
  }

  const isValidName = (name: string): boolean => {
    return name.length >= 3;
  }

  return (
    <div className={style.container}>
      {currentClub === undefined && <span className={style.clubContainer}>Clube não encontrado</span>}
      {currentClub !== undefined && (
        <>
          <div className={style.header}>
            <span className={style.clubName}>
              <span>Clube</span>
              {currentClub.name}
            </span>
            <span className={style.clubPrice}>
              <span className={style.clubPricePreffix}>R$</span>
              {isException ? currentClub.exceptionPrice : currentClub.price}
              <span className={style.clubPriceSuffix}>/mês</span>
            </span>
          </div>
          <div className={style.progressbar}>
            <div className={style.progressbarLine} style={{ width: `${currentProgress}%`}}/>
          </div>
          <div className={style.body}>
            {progress === STEPS.CONTACT && (
              <>
                <BaseInput
                  value={phoneNumber}
                  onChange={setPhoneNumber}
                  isValid={isValidPhone}
                  placeholder="(00) 00000-0000"
                  label="Telefone"
                  errorText="preencha o telefone corretamente"
                  type="tel"
                />
                <BaseInput
                  value={email}
                  onChange={setEmail}
                  placeholder="email@provedor.com"
                  isValid={isValidEmail}
                  label="E-mail"
                  errorText="preencha o e-mail corretamente"
                  type="email"
                />
                <BaseInput
                  value={name}
                  onChange={setName}
                  isValid={isValidName}
                  label="Nome"
                  errorText="preencha o nome corretamente"
                />
                <BaseButton
                  onClick={() => {
                    if (isValidPhone(phoneNumber) && isValidEmail(email) && isValidName(name)) {
                      setProgress(STEPS.PAYMENT);
                    }
                  }}
                  alt="Continuar"
                >
                  Continuar
                </BaseButton>
              </>
            )}
            {progress === STEPS.PAYMENT && (
              <>
                <BaseButton
                  className={style.backButton}
                  onClick={() => setProgress(progress - 1)}
                  alt="Voltar"
                  icon="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABoAAAAaCAYAAACpSkzOAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAs0lEQVR4nNXWPQoCMRCG4fcEWsgq+AOKlXowj6mlhZ2uhWujNuolVhYiLJIUwpcPHJj6IZNkZuBPYgKcgC3QzYlcgDrkDuiokTFQtZBPrpXIKIGUQE+FDMKd1F95BoZKpIwgVThlVuQKTFVIP4HcgJkSOUaQOzBXIocI8gAWKqRIIE9gqUKa2EeQF7BSIlaocJXO9hisz9v6Ya0tyNpUrWPCOviso9y6nNDCmpJtcq5bP8UbhdWADWvaLVIAAAAASUVORK5CYII="
                >
                  Voltar
                </BaseButton>
                <div className={style.contactReview}>
                  <div className={style.contactReviewTitle}>
                    <span className={style.contactReviewTitleBar} />
                    <span className={style.contactReviewTitleText}>Contato</span>
                    <span className={style.contactReviewTitleBar} />
                  </div>
                  <div>
                    {phoneNumber}
                    <br />
                    {email}
                    <br />
                    {name}
                  </div>
                </div>
                <div className={style.payment}>
                  <div className={style.paymentTitle}>Pagamento</div>
                <BaseButton
                  className={style.cardButton}
                  onClick={() => {}}
                  alt="Continuar"
                >
                  <span>Crédito</span>
                  <span>
                    <img src={`${process.env.REACT_APP_BASE_URL}/assets/icon-cards-master.svg`} alt="Mastercard" />
                    <img src={`${process.env.REACT_APP_BASE_URL}/assets/icon-cards-visa.svg`} alt="Visa" />
                    <img src={`${process.env.REACT_APP_BASE_URL}/assets/icon-cards-elo.svg`} alt="Elo" />
                    <img src={`${process.env.REACT_APP_BASE_URL}/assets/icon-cards-amex.svg`} alt="American Express" />
                  </span>
                </BaseButton>
                </div>
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
}
