type SurveyServiceOptions = {
  comments?: string;
  method: 'PUT' | 'POST';
  range: string;
  rate: number;
  suggestions?: string;
  tel?: string;
}

export default function sendSurveyService(options: SurveyServiceOptions): Promise<string> {
  const dateValue = new Date();
  const date = `${dateValue.getDate()}/${dateValue.getMonth() + 1}/${dateValue.getFullYear()}`;
  const tel = atob(options.tel || '');

  return fetch(`${process.env.REACT_APP_LOCAL_API_URL}/survey/index.php`, {
    method: options.method,
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      date,
      tel,
      rate: options.rate,
      suggestions: options.suggestions,
      comments: options.comments,
      range: options.range,
    }),
  })
    .then(res => res.json())
    .then(data => data?.updates?.updatedRange?.split(':')[0] || data?.updatedRange?.split(':')[0])
    .catch(err => console.error(err));
}
