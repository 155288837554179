import Schedules from './schedules.route';

export default function SchedulesPage() {
  return (
    <>
      <title>Clair de Lune Salão de Beleza - Agende agora!</title>
      <Schedules />
    </>
  );
}
