import { useEffect } from 'react'
import isMobile from '../../tools/is-mobile.tool'
import { useNavigate } from 'react-router-dom';

export default function Schedules() {
  const navigate = useNavigate();

  useEffect(() => {
    if (isMobile()) {
      navigate('/', { replace: true });
      window.location.href = "https://www.trinks.com/clair-de-lune/";
    }
  }, [navigate]);

  return (
    <div className="schedules-container">
      <iframe className="schedules-frame" src="https://www.trinks.com/clair-de-lune/framebusca" width="100%" height="100%" title="agendamento online clair de lune" />
    </div>
  );
}
