import Error from './error.route';

export default function ErrorPage() {
  return (
    <>
      <title>Clair de Lune Salão de Beleza - Ocorreu um erro</title>
      <Error />
    </>
  );
}
