import { useState } from 'react';

interface BaseInputProps {
  value: string;
  onChange: (value: string) => void;
  isValid: (value: string) => boolean;
  placeholder?: string;
  className?: string;
  label?: string;
  description?: string;
  errorText?: string;
  type?: 'text' | 'tel' | 'email';
}

const BaseInput: React.FC<BaseInputProps> = ({
  value,
  onChange,
  isValid,
  placeholder = '',
  className = '',
  label,
  description,
  errorText,
  type = 'text',
}) => {
  const [isFocused, setIsFocused] = useState(false);
  const [isInvalid, setIsInvalid] = useState(false);
  const descriptionText = (isInvalid && errorText) || description;

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const input = event.target.value;
    onChange(input);
  };

  const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let input = event.target.value;

    // Remove non-digit characters
    input = input.replace(/\D/g, '');

    // Apply mask
    if (input.length > 0) {
      input = '(' + input;
    }
    if (input.length > 3) {
      input = input.slice(0, 3) + ') ' + input.slice(3);
    }
    if (input.length > 10) {
      input = input.slice(0, 10) + '-' + input.slice(10);
    }
    if (input.length > 15) {
      input = input.slice(0, 15);
    }

    onChange(input);
  };

  const handleFocus = () => {
    setIsFocused(true);
  };

  const handleBlur = () => {
    setIsFocused(false);
    setIsInvalid(!isValid(value));
  };

  return (
    <div className={`base-input-container ${className}`}>
      {label && <label className="base-input-label">{label}</label>}
      <input
        type={type}
        value={value}
        onChange={type === 'tel' ? handlePhoneChange : handleInputChange}
        placeholder={placeholder}
        onFocus={handleFocus}
        onBlur={handleBlur}
        className={`base-input ${isFocused ? 'base-input--focused' : ''} ${isInvalid ? 'base-input--invalid' : ''}`.trim()}
      />
      {descriptionText && (
        <span className={`base-input-description ${isInvalid ? 'base-input-description--invalid' : ''}`}>
          {descriptionText}
        </span>
      )}
    </div>
  );
};

export default BaseInput;
