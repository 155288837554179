import { useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import getMarkdownFileService from '../../services/get-markdown-file';
import Markdown from 'react-markdown';
import Informations from '../../components/Informations';

export default function Article() {
  const params = useParams();
  const navigate = useNavigate();
  const [article, setArticle] = useState('');

  const getArticle = useCallback(async () => {
    try {
      const data = await getMarkdownFileService(`/articles/${params.articleName}.md`);
      setArticle(data);
    } catch {
      navigate("/error");
    }
  }, [navigate, params.articleName]);

  useEffect(() => {
    getArticle();
  }, [getArticle]);

  return (
    <>
      <div className="article-container">
        <Markdown>{article}</Markdown>
      </div>
      <Informations />
    </>
  );
}
