import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Markdown from 'react-markdown';

import ClubList from '../../components/ClubList';
import Informations from '../../components/Informations';

import getMarkdownFileService from '../../services/get-markdown-file';
import getNailClubs from '../../services/get-nail-clubs';
import getHairClubs from '../../services/get-hair-clubs';

const nailClubCards = getNailClubs();
const hairClubCards = getHairClubs();

export default function Home() {
  const [articles, setArticles] = useState('');

  async function getArticles() {
    const articlesData = await getMarkdownFileService('/articles/index.md');
    setArticles(articlesData);
  }

  useEffect(() => {
    getArticles();
  }, []);

  return (
    <div>
      <div className="home-banner" style={{ backgroundImage: `url('${process.env.REACT_APP_BASE_URL}/assets/background.jpg')` }}>
        <div>
          <span>Primeiro salão de beleza<br /> por assinatura de Pedra de Guaratiba!</span>
        </div>
      </div>
      <div className="home-schedule">
        <Link to="/agendar" className="home-schedule">
          Agende online agora mesmo
        </Link>
      </div>
      <div id="clubes" className="home-club-container" style={{ backgroundImage: `url('${process.env.REACT_APP_BASE_URL}/assets/background-club-nails.jpg')` }}>
        <div className="home-club-title">Clubes para unhas 💅🏻</div>
        <div className="home-club-description-container">
          <span className="home-club-description">
            Garanta que suas unhas estarão impecáveis
            a todo momento com nossos clubes
            que te dão direito a manicure/pedicure
            semanalmente com baixo custo!
          </span>
        </div>
        <ClubList cards={nailClubCards} />
      </div>
      <div className="home-club-container"  style={{ backgroundImage: `url('${process.env.REACT_APP_BASE_URL}/assets/background-club-hair.jpg')` }}>
        <div className="home-club-title">Clubes para cabelos 💇‍♀️</div>
        <div className="home-club-description-container">
          <span className="home-club-description">
            Tenha cabelos sempre saudáveis
            com rotinas de tratamentos exclusivas
            pensadas para as suas necessidades
            que só nossos clubes podem
            proporcionar!
          </span>
        </div>
        <ClubList cards={hairClubCards} />
      </div>
      <div className="home-articles">
        <div className="home-articles-container">
          <div className="home-articles-title">Artigos</div>
          <Markdown>
            {articles}
          </Markdown>
        </div>
      </div>
      <Informations />
    </div>
  );
}
