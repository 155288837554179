import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

export default function Error() {
  const [time, setTime] = useState(5);
  const navigate = useNavigate();

  useEffect(() => {
    const timeout = setTimeout(() => {
      navigate("/");
    }, 5000);

    const interval = setInterval(() => {
      setTime(Math.max(0, time - 1));
    }, 1000);

    return () => {
      clearTimeout(timeout);
      clearInterval(interval);
    }
  }, [navigate, time]);

  return (
    <div style={{ maxWidth: '800px', margin: '0 auto', textAlign: 'center' }}>
      Ocorreu um erro desconhecido.
      <br />
      Tente novamente mais tarde!
      <br /><br />
      Redirecionando para a página inicial... {time}s
    </div>
  );
}
