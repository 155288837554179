import { useEffect, useState, cloneElement, isValidElement, ReactElement } from 'react';
import type { PropsWithChildren } from 'react';
import style from './transition.module.scss';

type TransitionOptions = {
  type?: 'slide';
  mode?: 'in' | 'out' | 'both';
  isVisible?: boolean;
}

interface ChildProps {
  className?: string;
  onAnimationEnd?: () => void;
}

export default function Transition(options: PropsWithChildren<TransitionOptions>) {
  const { children, isVisible = true, type = 'slide', mode = 'both' } = options;
  const [show, setShow] = useState(isVisible);
  const [animationClassName, setAnimationClassName] = useState('');

  useEffect(() => {
    if (isVisible) {
      setShow(true);
      if (type === 'slide' && (mode === 'in' || mode === 'both')) {
        setAnimationClassName(style.slideIn);
      } else {
        setAnimationClassName('');
      }
    } else {
      if (type === 'slide' && (mode === 'out' || mode === 'both')) {
        setAnimationClassName(style.slideOut);
      } else {
        setAnimationClassName('');
      }
    }
  }, [isVisible, type, mode]);

  useEffect(() => {
    if (!isVisible && animationClassName !== '') {
      const animationDuration = 500; // Adjust based on your CSS animation duration
      const timer = setTimeout(() => {
        setShow(false);
      }, animationDuration);

      return () => clearTimeout(timer);
    }
  }, [isVisible, animationClassName]);

  if (!show) return null;

  if (isValidElement(children)) {
    const child = children as ReactElement<ChildProps>;
    const currentClassName = child.props.className || '';
    return cloneElement(child, {
      className: `${currentClassName} ${animationClassName}`.trim(),
      onAnimationEnd: () => {
        if(!isVisible) {
          setShow(false)
        }
        if (child.props.onAnimationEnd) {
          child.props.onAnimationEnd();
        }
      }
    });
  }

  return null;
}
