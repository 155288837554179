import { useEffect, useRef } from "react";

export type ModalData = {
  buttons?: ModalButton[];
  JSXContent?: React.FC;
  isVisible: boolean;
  onClose: () => void;
  text?: string;
}

export type ModalButton = {
  id: number;
  text: string;
  action: () => void;
}

export const initialModalData = { isVisible: false, text: '', onClose: () => {} };

export default function Modal(options: ModalData) {
  const { isVisible, text, buttons, onClose, JSXContent } = options;
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isVisible) modalRef.current?.focus();
  }, [isVisible])

  if (!isVisible) return null;

  return (
    <div ref={modalRef} className="modal-background requires-no-scroll">
      <div className="modal-container">
        <button className="modal-close" onClick={onClose}>X</button>
        {text && <p className="modal-text">{text}</p>}
        {JSXContent && <JSXContent />}
        {buttons?.map((button) => (
          <button key={button.id} className="modal-button" onClick={button.action}>{button.text}</button>
        ))}
      </div>
    </div>
  );
}
